<template>
  <section id="list-patrocinadores">
    <b-card no-body class="mb-0">
      <div class="ml-2 mt-2">
        <router-link
          :to="`/administrador/eventos_conf/${this.$route.params.id}`"
          class="link-none"
        >
          <feather-icon icon="ArrowLeftIcon" size="20" class="" />
        </router-link>
      </div>
      <div class="m-2">
        <info
          v-if="isInfoSidebarActive"
          :is-info-sidebar-active.sync="isInfoSidebarActive"
          :data="rowData"
          :participante="participante"
          @refresh="refresh()"
          @close="isInfoSidebarActive = false"
        />
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Mostrar</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>Participantes</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="searchValue" class="d-inline-block mr-1" placeholder="Pesquisar..." />
              </div>
          </b-col>
        </b-row>
      </div>
      <div class="overflow-auto">
        <b-table id="tableParticipantes" ref="tableParticipantes" hover striped :items="participantes" :fields="tableColumns" :filter="searchValue" primary-key="id" :total-rows="rows" :per-page="perPage" :sort-by="sortBy" :sort-desc="sortDesc" :current-page="currentPage" show-empty empty-text="Nenhuma informação encontrada" busy.sync="isBusy" empty-filtered-text="Nenhum registro filtrado">
          <template slot="empty">
            <div v-if="isBusy" class="text-center" >
              <b-spinner class="align-middle" />
              
            </div>
            <div v-else class="text-center">Nenhuma informação encontrada</div>
          </template>
          <template #cell(actions)="data">
            
            <a @click="info(data.item)">
              <feather-icon icon="InfoIcon" />
              <span class="align-middle ml-50"> Aprovar || Reprovar</span>
            </a>
            
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="tableParticipantes" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
  import { BCard, BRow, BCol, BTable, BFormInput, } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted } from '@vue/composition-api'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  import AddNew from './AddNew.vue'
  import AddNewUser from './AddNewUser.vue'
  import Info from './Info.vue'
  import axios from 'axios'

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BTable,
      vSelect,
      BFormInput,
      AddNew,
      AddNewUser,
      Info,
    },
    setup() {
      const isAddNewSidebarActive = ref(false)
      const isAddNewUserSidebarActive = ref(false)
      const isInfoSidebarActive = ref(false)
      return {
        isAddNewSidebarActive,
        isAddNewUserSidebarActive,
        isInfoSidebarActive,
      }
    },
    data() {
      return {
        perPage: 10,
        perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
        searchValue: '',
        rows: 0,
        tableColumns: [
          {
            key: 'name',
            label: 'NOME',
            sortable: true,
          },
          {
            key: 'cpf',
            label: 'CPF',
            sortable: true,
          },
          {
            key: 'created_at',
            label: 'DATA',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Ações',
          },
        ],
        rowData: {},
        sortBy: 'id',
        sortDesc: true,
        currentPage: 1,
        isBusy: false,
        participante: '',
        participantes: [
        ]
      }
    },
    watch: {
      isAddNewSidebarActive(value) {
        if (!value) {
          this.getListParticipantes();
        }
      },
      isInfoSidebarActive(value) {
        if (!value) {
          this.getListParticipantes();
        }
      }
    },
    methods: {
      getLogoUrl(logo) {
        return `${process.env.VUE_APP_API_BASE}/storage/${logo}`
      },
      refresh(saved) {
        this.getListParticipantes();
      },
      getListParticipantes(){

        axios.get(`${process.env.VUE_APP_API}/events/get-solicitations/${this.$route.params.id}`)
          .then(response => {
            this.participantes = response.data.response.participantes.map(participante => {
              const createdAt = participante.created_at;
              const formattedDate = new Date(createdAt).toLocaleDateString('pt-BR');
              return {
                ...participante,
                created_at: formattedDate
              };
            });
            console.log('heyhey', this.participantes);
          })
          .catch(error => {
            console.log(error);
          });

      },
      info(data) {
        this.rowData.id = data.id
        this.rowData.name = data.name
        this.rowData.cpf = data.cpf
        this.rowData.email = data.email
        this.isInfoSidebarActive = true
      },
    },
    beforeMount() {
      this.getListParticipantes()
    },
  }
</script>

<style lang="scss">

</style>